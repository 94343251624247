* {
  padding: 0;
  margin: 0;
}
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background-color: #f0f0f0;
  width: 100%;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

html {
  min-height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: #f0f0f0;
  font-family: 'Sora', sans-serif;
}
/* 
body,
#root {
  /* height: 100%;
  min-height: 100%; */
/* } */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Sora', sans-serif;
  color: #1f2023;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  box-sizing: border-box;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.mainApp {
  margin: auto;
  width: fit-content;
  max-width: 100%;
  padding: 60px 30px 30px 30px;
}

.animated {
  animation-duration: 700ms !important;
}

.td-100 {
  animation-delay: 100ms;
}

.td-200 {
  animation-delay: 200ms;
}

.td-300 {
  animation-delay: 300ms;
}

.td-400 {
  animation-delay: 400ms;
}

.td-500 {
  animation-delay: 500ms;
}

.td-600 {
  animation-delay: 600ms;
}

.td-700 {
  animation-delay: 700ms;
}
.mb-15 {
  margin-bottom: 15px;
}

.mb-30 {
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  html {
    background: #fff;
  }
  .main__container {
    box-shadow: none !important;
  }
  .mainApp {
    padding: 30px;
  }
  .main__container,
  .main__card {
    padding: 0 !important;
  }
  .main__card {
    box-shadow: none !important;
  }
  .main-header {
    padding-bottom: 30px;
  }
}
.spinner {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 200px;
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #f0f0f0;
}
.spinner__logo {
  display: block;
}
.spinner_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.spinner_container .expired-payment-header-content {
  margin-top: 15px;
  font-size: 12px;
}

.input_field:focus {
  outline: none;
}

.fade-text:hover {
  opacity: 0.6;
  transform: translateY(-2px);
}
select {
  border: none !important;
}

select:focus {
  outline: none;
}

option {
  font-size: 18px;
  background-color: #ffffff;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

hr {
  opacity: 0.3;
}

@font-face {
  font-family: 'PolySans';
  src: url('./fonts/PolySans-MedianWide.otf') format('opentype');
}
